
import * as FilePond from 'filepond';
import * as utils from './galcom-utils.js';

console.log("GAL galcom_desk loading");
frappe.provide("galcom.desk");
window.galcom.desk = {
    checkFrequencyString: utils.checkFrequencyString,
    synthesizeVPDialog: async (frm,fieldname) =>{

        var attachmentFields=[];
        frm.fields.forEach(field =>{
            if(field.df.fieldtype === "Attach")
                attachmentFields.push(field.df.fieldname);
        })

        const langOptions = (await frappe.call("galcom.recordings.rec_utils.speech_voices")).message;
        const langField = {
                    label:"Language",
                    fieldname:"voice",
                    fieldtype:"Select",
                    options: langOptions,
                };
        const fieldSelector = {
                    label:"VP for field:",
                    fieldname: "vp_fieldname",
                    fieldtype: "Select",
                    options: attachmentFields,
                };
        const textField = {
                    label:"Text to Synthesize",
                    fieldname:"text_to_synthesize",
                    fieldtype: "Data",
                };
        var dialogFields;
        if(fieldname != null)
            dialogFields = [langField,textField];
        else
            dialogFields = [langField,fieldSelector,textField];

        console.log("lang options: ",langOptions);
        var dialog = new frappe.ui.Dialog({
            title:"Synthesize VP",
            fields:dialogFields,
            primary_action_label: "Synthesize",
            async primary_action(values){
                console.log("synthesizeing ",values);

                const language = values.voice.split("|")[1].trim();

                const result = await frappe.call("galcom.recordings.rec_utils.generate_speech",{
                    text: values.text_to_synthesize,
                    language: language,
                    fieldname: fieldname || values.vp_fieldname,
                    doctype: frm.doc.doctype,
                    doc_name:frm.doc.name,
                });

                dialog.hide();

            }
        });

        dialog.show();


    }

}
window.FilePond=FilePond;